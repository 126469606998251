
$(function() {
	$("a[href$='#']").click(function(e) {
		e.preventDefault();
	});
	
	$('a.lien-image-bande-texte').each(function() {	
		var $img = $(this).find('img'),imgTitle = $img.attr('title'),imgDesc = $img.attr('alt'),domcreate='';
		if($img && !$(this).find('span.lien-bande-titre').length){
			domcreate+='<span class="lien-bande-titre">'+imgTitle+'</span>';
			if(imgDesc || $(this).find('span.credit-photo').length){ domcreate+='<span class="lien-bande-description">'+imgDesc+'</span>';}
			if(domcreate){
				$(this).append(domcreate);
				if($(this).find('span.credit-photo').length){ $(this).find('span.credit-photo').detach().appendTo($(this).find('span.lien-bande-description'));}
				$(this).addClass('jsChanged');
			}
		}
		//// mod
		if($(this).parent('div.div-credit-photo').length) $(this).unwrap();		
		///
	});
	
	$(window).scroll(function () {($(this).scrollTop() > 20)?$('#back-top').stop(true).addClass('back-top-show'):$('#back-top').stop(true).removeClass('back-top-show');});$('#back-top').click(function () {$('body, html').stop(true).animate({scrollTop: 0}, 600);});
	

	$(document).foundation();
});